<template>
  <Header />
  <div class="row text-left no-border q-mt-md q-mt-xl">
    <div class="col-md-6 container-margin">
      <q-card class="no-border" style="height: 90vh">
        <q-card-section class="no-border row justify-between q-pb-none">
          <p>{{ $t("ControlPanel.card") }}</p>
          <router-link to="/">
            <q-btn flat round dense right icon="close" class="desktop-hide" />
          </router-link>
        </q-card-section>
        <q-card-section class="no-border q-pb-none q-pt-none">
          <div class="text-h5 text-weight-bold">
            ¡{{ $t("ControlPanel.hello-card") }}, {{ user.name }}!
          </div>
          <p class="q-ma-none q-pa-none">
            {{ $t("ControlPanel.welcome") }}
            <span class="text-weight-bold"> {{ settings.system_name }} </span>.
            {{ $t("ControlPanel.welcome2") }}
          </p>
        </q-card-section>
        <q-card-section class="q-pb-none">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <p class="q-ma-none">{{ $t("General.balance") }}</p>
            <router-link to="/transacciones">
              <q-card-section
                class="underline-text title-transacciones q-pa-sm text-weight-bold"
              >
                {{ $t("Home.see-movements") }}
              </q-card-section>
            </router-link>
          </div>
          <div class="text-h4 text-weight-bold">
            {{ points.points.points }}
            {{ settings.currency_abbreviation }}
          </div>
          <p class="q-ma-none q-pa-none">
            {{ $t("ControlPanel.card-number") }}:
            <span class="text-weight-bold">{{ user.card }}</span>
          </p>
          <!-- <p class="q-ma-none q-pa-none">
            {{ $t("ControlPanel.address") }}:
            <span class="text-weight-bold" v-if="user.shipping_address">{{
              user.shipping_address
            }}</span>
            <span
              class="text-weight-bold underline-text"
              v-else
            >
              <router-link to="/profile">
                {{ $t("Profile.personal-address.please-introduce-address") }}:
              </router-link></span
            >
          </p> -->
        </q-card-section>

        <!-- <q-card-section>
          <p class="text-h6 q-ma-none q-pa-none">
            {{ $t("ControlPanel.download-card") }}
          </p>
          <p class="q-ma-none q-pa-none">
            Convierte tu pasión por los cómics en una verdadera recompensa.
            {{ $t("ControlPanel.info-card") }}
          </p>
        </q-card-section> -->
       
      </q-card>
    </div>
  </div>
  <Footer />
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapState, mapActions } from "vuex";
import settings from "@/mixins/settings";

const Header = defineAsyncComponent(() =>
  import("../../shared/components/Header.vue")
);

const Footer = defineAsyncComponent(() =>
  import("../../shared/components/Footer.vue")
);

export default {
  mixins: [settings],

  name: "Home",
  components: {
    Header,
    Footer,
  },
  

  methods: {
    ...mapActions("points", ["getPoints"]),

    ...mapActions("transactions", ["getTransactions"]),
    
  },

  computed: {
    
    ...mapState("auth", ["user"]),

    points() {
      return this.$store.state.points.points;
    },
    transactions() {
      return this.$store.state.transactions.transactions;
    },
  },

  // mounted() {
  //   this.getPoints();
  // },
};
</script>
<style lang="scss" scoped>
.q-card {
  border-radius: 25px 25px 0px 0px;
}

.title-transacciones {
  color: v-bind("settings.color_accent");
}
</style>
